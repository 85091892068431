import * as React from 'react';
import {
  Box,
  Center,
  HStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { Bars3Icon  } from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import LightNightToggle from "../light-night-toggle";
import MobileMenuButton from "./mobile-menu-button";
import Link from "next/link";

const MobileNavigation = () => {

  const MotionBox = motion(Box);

  return (
    <MotionBox
    position="fixed"
    bottom="0"
    right="0"
    left="0"
    zIndex={20}
    display={{ base: "block", md: "none" }}
    >
      <HStack
        justify="space-around"
        align="center"
        py={2}
        mt="auto"
        height={16}
        bg={useColorModeValue('gray.100', 'gray.700')}
        borderTopWidth="1px"
        borderTopColor={useColorModeValue("neutral.400", "neutralD.400")}
        shadow="0 -2px 10px 0 rgba(0,0,0, 0.035);"
      >
        {/*
        <Center w="100%">
          <Link href="/blog">
            <MobileMenuButton
              label="Blog"
              icon={<PencilIcon />}
              ></MobileMenuButton>
          </Link>
        </Center>
      */}

        <Center w="100%">
        <Link href="/">
            <MobileMenuButton
              label="Menu"
              icon={<Bars3Icon />}
            ></MobileMenuButton>
          </Link>
        </Center>
        


        <Center w="100%">
          <LightNightToggle mobile />
        </Center>
        {/*   <Center w="100%">
          <Link href="/newsletter" px={6}>
            <MobileMenuButton
              label="Newsletter"
              icon={<MailIcon />}
            ></MobileMenuButton>
          </Link>
        </Center> */}
        {/* <Center w="100%">
          <NewsletterDrawer mobile />
        </Center> */}
      </HStack>
    </MotionBox>
  );
};

export default MobileNavigation;
