import { Select, SelectProps } from '@chakra-ui/react'
import { useRouter } from 'next/router'

function LanguageSwitcher(props: SelectProps) {
  const router = useRouter()

  const languages = [
    {
      label: '🇬🇧',
    },
    { label: '🇫🇷', url: '/fr' },
    //{ label: '🇪🇸 Español ', url: '/' },
    //{ label: '🇩🇪 Deutsch', url: '/' },
  ]

  const currentLanguagesUrl = languages[0].url

  return (
    <Select
      marginStart='1.4rem'
      variant='unstyled'
      fontWeight='semibold'
      color='gray.600'
      _dark={{ color: 'whiteAlpha.600' }}
      value={currentLanguagesUrl}
      aria-label={`Select your language`}
      onChange={(e) => {
        router.push(e.target.value)
      }}
      {...props}
    >
      {languages.map(({ label, url }) => (
        <option key={`labelurl`} value={url}>
          {label}
        </option>
      ))}
    </Select>
  )
}

export default LanguageSwitcher
