import {
  Box,
  Flex,
  HStack,
  HTMLChakraProps,
  IconButton,
  Link,
  chakra,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useUpdateEffect,
  Text
} from '@chakra-ui/react'
import { useScroll } from 'framer-motion'
import NextLink from 'next/link'
import { useEffect, useRef, useState } from 'react'
import { SunIcon, MoonIcon  } from "@heroicons/react/24/solid";
import Logo, { LogoIcon } from './logo'
import Search from './omni-search'
import VersionSwitcher from './language-switcher'
import siteConfig from 'configs/site-config.json'
import MobileNavigation from "./mobile/mobile-navigation";
import useSound from "use-sound";


function HeaderContent() {
  const mobileNav = useDisclosure()

  // Mode jour:nuit
  // const { toggleColorMode: toggleMode } = useColorMode()
  const { colorMode, toggleColorMode } = useColorMode();
  const [play] = useSound("/lightswitch.mp3", {
    volume: 0.05,
    sprite: {
      on: [0, 300],
      off: [500, 300],
    },
  });

  const handleClick = () => {
    toggleColorMode();
    colorMode === "dark" ? play({ id: "on" }) : play({ id: "off" });
  };

  const text = useColorModeValue('dark', 'light')
  const SwitchIcon = useColorModeValue(MoonIcon, SunIcon)
  const mobileNavBtnRef = useRef<HTMLButtonElement>()

  
  useUpdateEffect(() => {
    mobileNavBtnRef.current?.focus()
  }, [mobileNav.isOpen])

  return (
    <>
      {/* Menu responsive */}
      <MobileNavigation />


      <Flex w='100%' h='100%' px='6' align='center' justify='space-between'>
        <Flex align='center'>
          <NextLink href='/' passHref>
            <chakra.a display='block' aria-label='Chakra UI, Back to homepage'>
              <Logo display={{ base: 'none', md: 'block' }} />
              <Box minW='3rem' display={{ base: 'block', md: 'none' }}>
                <LogoIcon />
              </Box>
            </chakra.a>
          </NextLink>
        </Flex>

        <Flex
          justify='flex-end'
          w='100%'
          align='center'
          color='gray.400'
          maxW='1100px'
        >
          <Search />
         
         {/*
          <HStack spacing='5' display={{ base: 'none', md: 'flex' }}>
            <Link
              isExternal
              aria-label='Learn HTML & CSS'
              href={siteConfig.repo.url}
            >
             <Text>HTML & CSS</Text>
            </Link>
            <Link
              m="10rem"
              isExternal
              aria-label='Learn HTML & CSS'
              href={siteConfig.repo.url}
            >
             <Text>Javascript</Text>
            </Link>
            
            
          </HStack>
         */}
          <VersionSwitcher
            width='auto'
            flexShrink={0}
          />
          <HStack
            spacing='5'
            // Désactive en mode responsive
            display={{ base: 'none', md: 'flex' }}
          >
            {/*  Mode Jour/Nuit */}
            <IconButton
              size='xs'
              fontSize='md'
              aria-label={`Switch to ${text} mode`}
              variant='ghost'
              color='current'
              ml={{ base: '0', md: '3' }}
              onClick={handleClick}
              icon={<SwitchIcon height="20px" />}
            />
          </HStack>
        </Flex>
      </Flex>
      {/*
      <MobileNavContent isOpen={mobileNav.isOpen} onClose={mobileNav.onClose} />
    */}
      </>
  )
}

function Header(props: HTMLChakraProps<'header'>) {
  const { maxW = '8xl', maxWidth = '8xl' } = props
  const ref = useRef<HTMLHeadingElement>()
  const [y, setY] = useState(0)
  const { height = 0 } = ref.current?.getBoundingClientRect() ?? {}

  const { scrollY } = useScroll()
  useEffect(() => {
    return scrollY.onChange(() => setY(scrollY.get()))
  }, [scrollY])

  return (
    <chakra.header
      ref={ref}
      shadow={y > height ? 'sm' : undefined}
      transition='box-shadow 0.2s, background-color 0.2s'
      pos='sticky'
      top='0'
      zIndex='3'
      bg='white'
      _dark={{ bg: 'gray.800' }}
      left='0'
      right='0'
      width='full'
      {...props}
    >
      <chakra.div height='4.5rem' mx='auto' maxW={maxW} maxWidth={maxWidth}>
        <HeaderContent />
      </chakra.div>
    </chakra.header>
  )
}

export default Header
